import React from "react";

export const Footer: React.FC = () => {
	return (
		<div style={{
            backgroundColor: "white",
            height: "64px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>
        <a href="http://cgconsultoresjuridicos.com/" target="_blank" rel="noreferrer">CG Consultores Juridicos 2023</a>
    </div>);
};
