import { useState, useEffect } from "react";
import { useList, HttpError } from "@pankod/refine-core";
import { AntdLayout, AutoComplete, Input, Icons, Typography } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router-v6";
import debounce from "lodash/debounce";
import { ISolicitudCD, IOptions } from "interfaces";
const { Link } = routerProvider;
const { Text } = Typography;
const { SearchOutlined } = Icons;

const renderTitle = (title: string) => {
    return (
        <Text strong style={{ fontSize: "16px" }}>
            {title}
        </Text>
    );
};

const renderItem = (title: string, resource: string, id: number) => {
    return {
        value: title,
        label: (
            <Link to={`/${resource}/show/${id}`}>
                <Text>{title}</Text>
            </Link>
        ),
    };
};

export const Header: React.FC = () => {
    const [value, setValue] = useState<string>("");
    const [options, setOptions] = useState<IOptions[]>([]);

    const { refetch: refetchSolicitudCD, isError } = useList<ISolicitudCD, HttpError>({
        resource: "solicitudcambiodatos",
        config: {
            filters: [
               { field: "id_sol", operator: "contains", value  }
               ],
        },
        queryOptions: {
            enabled: false,
            onSuccess: (data) => {
                const solicitudCDOptionGroup = data.data.map((item) =>
                    renderItem(item.id_sol, "solicitudcambiodatos", item.id),
                );
                if (solicitudCDOptionGroup.length > 0) {
                    setOptions((prevOptions) => [
                        ...prevOptions,
                        {
                            label: renderTitle("CN con solicitud"),
                            options: solicitudCDOptionGroup,
                        },
                    ]);
                }
            },
        },
    });

    useEffect(() => {
        setOptions([]);
        refetchSolicitudCD();
    }, [value]);

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <AntdLayout.Header
            style={{
                padding: "0px 24px",
                backgroundColor: "#FFF",
            }}
        >
            <AutoComplete
                style={{ width: "100%", maxWidth: "550px" }}
                options={options}
                filterOption={false}
                onSearch={debounce((value: string) => setValue(value), 500)}
            >
                <Input
                    size="large"
                    placeholder="Buscar Solicitudes de Cambio de Datos"
                    suffix={<SearchOutlined />}
                />
            </AutoComplete>
        </AntdLayout.Header>
    );
};
