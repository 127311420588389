import { AuthProvider, HttpError } from "@pankod/refine-core";
import { AuthHelper } from './helpers';
import axios from "axios";

export const drfJWTAuthProvider = (apiUrl: string) => {
    const axiosInstance = axios.create();
    const TOKEN_KEY = "refine-auth";
    const drfAuthHelper = AuthHelper(apiUrl);

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const customError: HttpError = {
                ...error,
                message: error.response?.data?.message,
                statusCode: error.response?.status,
            };

            return Promise.reject(customError);
        },
    );

    const authProvider: AuthProvider = {
        login: async ({ username, password }) => {
            const { data } = await drfAuthHelper.login(username, password);
            if ( data ) {
                localStorage.setItem(TOKEN_KEY, data.access);

                // set header axios instance
                axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.access}`

                return Promise.resolve();
            }
            return Promise.resolve();
        },
        logout: () => {
            localStorage.removeItem(TOKEN_KEY);
            return Promise.resolve();
        },
        checkError: (error) => {
            const status = error.statusCode;
            if (status === 401 || status === 403) {
                localStorage.removeItem(TOKEN_KEY);
                return Promise.reject();
            }
            return Promise.resolve();
        },
        checkAuth: async () => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (token && !drfAuthHelper.isJwtExpired(token)) {
                axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}` 
                return Promise.resolve();
            }
            return Promise.reject();
        },
        getPermissions: () => Promise.resolve(),
        getUserIdentity: async () => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (!token) {
                return Promise.reject();
            }
            return Promise.resolve(drfAuthHelper.jwtDecoded(token));
        },
        forgotPassword: ({ email }) => {
            // send password reset link to the user's email address here
            // if request is successful, resolve the Promise, otherwise reject it
            return Promise.resolve();
        },
        updatePassword: ({ password }) => {
            // update the user's password here
            // if request is successful, resolve the Promise, otherwise reject it
            return Promise.resolve();
        },
    };
    return {
        authProvider,
        axiosInstance
    };
};