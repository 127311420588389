import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@pankod/refine-core";
import { useTable, List, Table, Space, ShowButton, DateField, getDefaultSortOrder } from "@pankod/refine-antd";
import { ISolicitudCD } from "interfaces";

export const SolicitudCDList: React.FC<IResourceComponentsProps > = () => {
    const { tableProps, sorter } = useTable<ISolicitudCD>({
        syncWithLocation: true,
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex="codigocn_pers" title="CN"
                    sorter={{ multiple: 2 }}
                    defaultSortOrder={getDefaultSortOrder("codigocn_pers", sorter)}
                    />
                <Table.Column
                    dataIndex="id_sol"
                    title="Clave"
                    />
                <Table.Column
                    dataIndex="tipo_sol"
                    title="Tipo"
                    />
                <Table.Column
                    dataIndex="detalle_sol"
                    title="Detalle"
                    />
                <Table.Column
                    dataIndex={["fecha_sol"]}
                    title="Fecha Sol"
                    render={(value: any) => <DateField value={value} />}
                    sorter={{ multiple: 1 }}
                    defaultSortOrder={getDefaultSortOrder("fecha_sol", sorter)}
                />
                <Table.Column
                    dataIndex="status_sol"
                    title="Status Sol"
                    />
                <Table.Column
                    title="Mas Info"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}/>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
