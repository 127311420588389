import "@pankod/refine-antd/dist/reset.css";
import routerProvider from "@pankod/refine-react-router-v6";
import React from "react";
import { Refine } from "@pankod/refine-core";
import { notificationProvider, ReadyPage, ErrorComponent } from "@pankod/refine-antd";
import { Title, Header, Sider, Footer, Layout, OffLayoutArea } from "components/layout";
import { SolicitudCDList, SolicitudCDShow } from "./pages/solicitudcd";
// import { AntdInferencer } from "@pankod/refine-inferencer/antd";
import { drfJWTAuthProvider } from "./authProvider";
import { DataProvider } from "./dataProvider";
import { Login } from "pages/login";

function App() {
  const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost:8000";
  const { authProvider, axiosInstance } = drfJWTAuthProvider(API_URL);
  const dataProvider = DataProvider(API_URL, axiosInstance);
  return (
    <Refine
      disableTelemetry
      LoginPage={Login}
      authProvider={authProvider}
      dataProvider={dataProvider}
      routerProvider={routerProvider}
      notificationProvider={notificationProvider}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      resources={[
        {
          name: "solicitudcambiodatos",
          list: SolicitudCDList,
          show: SolicitudCDShow,
        },
      ]}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
    />
  );
}

export default App;
