import React from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { Steps, Descriptions, Result } from 'antd';
import { Show } from "@pankod/refine-antd";
import Moment from 'moment';

const getEstatusStyle = (veredicto: string) => {
    if (veredicto) {
        return veredicto === "FAVORABLE" ? "success" : "warning";
    }
    return "info";
};

const getEstatus = (veredicto: string) => {
    if (veredicto) {
        return veredicto === "FAVORABLE" ? "APROBADO" : "RECHAZADO";
    }
    return "info";
};

const getSpan = (report: any) => {
    if (report === undefined) return 2;
    console.log(report);
    return 3;
}

export const SolicitudCDShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Descriptions bordered title={record?.id_sol}>
                <Descriptions.Item label="CN">{record?.codigocn_pers}</Descriptions.Item>
                <Descriptions.Item label="Nombre" span={2}>{record?.get_nombre}</Descriptions.Item>
                <Descriptions.Item label="Red / Gerencia">{record?.get_redgerencia}</Descriptions.Item>
                <Descriptions.Item label="Tipo">{record?.tipo_sol}</Descriptions.Item>
                <Descriptions.Item label="Detalle">{record?.detalle_sol}</Descriptions.Item>
                <Descriptions.Item label="Progreso">
                { record?.flujo_trabajo ? (
                    <Steps
                        direction="vertical"
                        progressDot
                        responsive
                        current={getSpan(record?.flujo_trabajo[3]?.finished_iso)}
                        items={[
                            {
                                title: "Envío de la Solicitud",
                                description: <p>{Moment(record?.fecha_sol).format('YYYY-MM-DD HH:mm:ss') ?? "Pendiente"}</p>,
                            },
                            {
                                title: "Validación de la Solicitud",
                                description: <p>{Moment(record?.flujo_trabajo[2]?.finished_iso).format('YYYY-MM-DD HH:mm:ss') ?? "Pendiente"}</p>,
                            },
                            {
                                title: "Resultado de la Solicitud",
                                description: <p>{Moment(record?.flujo_trabajo[3]?.finished_iso).format('YYYY-MM-DD HH:mm:ss') ?? "Pendiente"}</p>,
                            },
                        ]}/>
                    ) : (
                    <Steps
                        direction="vertical"
                        progressDot
                        responsive
                        current={0}
                        items={[
                            {
                                title: "Envío de la Solicitud",
                                description: <p>{Moment(record?.fecha_sol).format('YYYY-MM-DD HH:mm:ss') ?? "Pendiente"}</p>,
                            },
                            {
                                title: "Validación de la Solicitud",
                                description: "Pendiente",
                            },
                            {
                                title: "Resultado de la Solicitud",
                                description: "Pendiente",
                            },
                        ]}/>
                    )
                }
                </Descriptions.Item>
                <Descriptions.Item label="Estatus" span={2}>
                { record?.estatus ? (
                    <Result
                        status={getEstatusStyle(record?.estatus?.resultado?.veredicto)}
                        title={getEstatus(record?.estatus?.resultado?.veredicto)}
                        subTitle={record?.estatus.comentarios}/>
                    ) : ( 
                    <Result
                        status="info"
                        title="En proceso"/>
                    )
                }
                </Descriptions.Item>
            </Descriptions>

        </Show>
    );
};
