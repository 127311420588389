import jwt_decode from 'jwt-decode';
import axios from "axios";

interface ILoginResponse {
    access: string;
    refresh: string;
}

interface IGroup {
    id: number | string;
    name: string;
}

interface IUser {
    id: number | string;
    username: string;
    email: string;
    group: IGroup;
    created_at: string;
    updated_at: string;
}

interface IToken {
    token_type: string;
    exp: number;
    iat: number;
    jti: number;
    user_id: number;
}

export const AuthHelper = (apiUrl: string) => ({
    login: async (username: string, password: string) => {
        const url = `${apiUrl}/rest/token/`;

        return await axios.post<ILoginResponse>(url, {
            username,
            password,
        });
    },
    jwtDecoded: (token: string) => {
        return jwt_decode<IToken>(token);
    },
    isJwtExpired: (token: string) => {
        let decodedToken = jwt_decode<IToken>(token);
        let currentDate = new Date();
        return decodedToken.exp * 1000 < currentDate.getTime()
    },
    me: async (token: string) => {
        return await axios.get<IUser>(`${apiUrl}/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
});
